import React from 'react';
import styled from 'styled-components';
import { useIntl } from "gatsby-plugin-intl";
import ContactForm from 'components/pages/Contact/ContactForm/ContactFormV2Amazon';

import { MainSection }  from 'components/pages/Contact/styledElements';

const Row  = styled.div`
  max-width:1200px;
  margin-right:auto;
  margin-left:auto;
  padding-top: 20px;
  padding-bottom: 20px;

  .forms-container {
    width: 100%;
  }

  #contact-form-container .contact-form-header {
    padding-left: 10px;
    padding-right: 10px;
  }

  .forms-container .contact-form-header{
    color: #000;
    font-size: 28px;
    text-align: center;
    font-weight: 500;
  }

  #contact-form-container .contact-form-description {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
  }

  #contact-form-container form > div:last-child {
    margin-bottom: 10px;
  }

//   .contact-column {
//     padding: 20px;
//   }

  #contact-form-container form input, #contact-form-container form textarea {
    border: 2px solid #fff;
    text-transform: capitalize;
    padding: 6px 15px;
    border-radius: 10px;
  }

  #contact-form-container form input[type=email], #contact-form-container form textarea {
    text-transform: none;
  }

  #contact-form-container form {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  .box-shadow {
    // -webkit-box-shadow: 0px 0px 8px 5px rgba(181,181,181,0.3);
    // box-shadow: 0px 0px 8px 5px rgba(181,181,181,0.3);
    background: #00AF85;
    padding: 20px;
    border-radius: 10px;
  } 

  form > div {
    margin-left: 0 !important;
  }

  #contact-form-container form button {
    margin-top: 20px !important;
    background-color: #fff;
    color: #000;
  }

  #contact-form-container form button:hover {
    color: #00AF85;
  }

  #contact-form-container form {
      margin-bottom: 0 !important;
  }


  @media only screen and (max-width: 981px) {
    #contact-form-container form > div {
      margin-left: 0;
      margin-right: 0;
    }
    #calendly-form-container .box-shadow {
      padding: 20px 30px;
    }
  }
  

  @media only screen and (min-width: 982px) {
    .contact-column {
      display: inline-block;
    //   width: 50%;
      vertical-align: top;
    }

    .box-shadow {
      padding: 30px 20px;
    }

    .forms-container .contact-form-header {
      font-size: 36px;
      margin-bottom: 45px;
    }

    padding-top: 0px;
    padding-bottom: 70px;
  }
`;

const ContactFormHeaderAmazon = () => {
  const intl = useIntl()
  return (
    <MainSection>
        <Row>
            <div className="forms-container">
                {/* <h3 className="contact-form-header">
                  {intl.formatMessage({ id: "c-contact-subheader" })}
                </h3> */}
                <div id="contact-form-container" className="contact-column">
                  <div className="box-shadow">
                    {/* <p className="contact-form-description">
                    {intl.formatMessage({ id: "c-contact-description" })}
                    </p> */}
                    <p className="contact-form-description">
                        {intl.formatMessage({ id: "c-contact-subheader" })}
                    </p>
                    <ContactForm />
                  </div>
                </div>
            </div>
        </Row>
    </MainSection>
  );
};

export default ContactFormHeaderAmazon;
